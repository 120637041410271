import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { API_URL, API_URL_FRONTEND } from "./config";

export const PrivacyDispatcher = (props) => {
  const [page, setPage] = useState({});

  const loadPrivacyPolicy = async () => {
    try {
      const params = new URLSearchParams(props.location.search);

      const res = await axios({
        url: `${API_URL_FRONTEND}/pages/informativa-privacy${
          !!params.get("institutional") &&
          params.get("institutional") === "true"
            ? "?institutional=true"
            : ""
        }`,
        method: "GET",
        headers: {
          storeid: props.match.params.storeId,
        },
      });
      return res.data;
    } catch (e) {
      console.log(e);
      props.history.push("/errore");
    }
  };

  useEffect(async () => {
    const page = await loadPrivacyPolicy();
    setPage(page);
  }, []);

  console.log("props", props);
  return (
    <div style={{ margin: 24 }}>
      {page?.title}
      <div>
        <div
          // contentEditable="true"
          dangerouslySetInnerHTML={{ __html: page?.content }}
        ></div>
      </div>
    </div>
  );
};

import React from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  Switch,
  useHistory,
  useParams,
} from "react-router-dom";
import { PrivacyDispatcher } from "./src/PrivacyDispatcher";
import { ErrorPage } from "./src/ErrorPage";

export const Router = (props) => {
  return (
    <BrowserRouter>
      <Switch>
        <Route
          exact
          path="/errore"
          render={(props) => <ErrorPage {...props} />}
        />
        <Route
          exact
          path="/:storeId"
          render={(props) => <PrivacyDispatcher {...props} />}
        />
      </Switch>
    </BrowserRouter>
  );
};
